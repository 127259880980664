.portfolio-form h1 {
    margin-bottom: 0px;
}

.portfolio-form .edit-notice {
    font-size: 16px;
    color: rgb(60, 60, 60);
    margin-top: 5px;
    margin-bottom: 40px;
}

.portfolio-form h2 {
    margin-top: 70px;
}
.portfolio-form h2:nth-of-type(1) {
    margin-top: 10px;
}

.portfolio-form h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
}

.portfolio-form .error-msg {
    color: red;
    margin-bottom: 30px;
}

.portfolio-form .logo-upload .upload-button {
    position: relative;
}

.portfolio-form .logo-upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.portfolio-form .logo-preview-container {
    position: relative;
    display: inline-block;
    height: 75px;
    width: 75px;
    vertical-align: middle;
    margin-right: 20px;
    background-color: rgba(235, 235, 235, 0.5);
    border: 3px solid rgba(var(--secondary-color));
    border-radius: 50%;
}

.portfolio-form .logo-preview-container-inner {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.portfolio-form .logo-preview-container-inner img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.portfolio-form .logo-preview-container .logo-remove {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    opacity: 0.7;
    right: -5px;
    top: -5px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.portfolio-form .document-upload {
    position: relative;
    height: 100px;
    background-color: rgba(var(--secondary-color), 0.3);
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
}
.portfolio-form .document-upload:hover,
.portfolio-form .document-upload.drag-active {
    background-color: rgba(var(--secondary-color), 0.4);
    transform: scale(1.015);
}

.portfolio-form .document-upload .form-upload {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
}

.portfolio-form .document-upload input {
    position: absolute;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 10;
}

.portfolio-form .files-preview {
    background-color: rgba(var(--secondary-color), 0.3);
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px 20px 0px 20px;
}

.portfolio-form .files-preview .file-container {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
}
.portfolio-form .files-preview .file-container:last-of-type {
    margin-right: 0px;
}

.portfolio-form .files-preview .file {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}

.portfolio-form .files-preview .file .file-icon {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.portfolio-form .files-preview .file .file-name {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
}