@keyframes appear {
  0% {opacity: 0;}
  100% {opacity: 1;}
}


:root {
  --primary-color: 57, 122, 140;
  --secondary-color: 122, 146, 94;
}

body {
  background-color: rgb(245, 245, 245);
}


.appear {
  animation: 0.5s 1 forwards appear;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;
}

.icon-text span{
  vertical-align: super;
}


.app {
  --horizontal-margin: 10%;
  display: flex;
  flex-flow: column;
  height: 100vh;
}


.header {
  position: fixed;
  --title-width: 400px;
  z-index: 101;
  flex: 0 1 auto;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 75px;
  box-shadow: 0px 0px 4px 2px #0000001b;
}

.header-title {
  position: absolute;
  width: var(--title-width);
  height: 60%;
  top: 50%;
  left: var(--horizontal-margin);
  transform: translateY(-50%);
  margin: 0px;
}

.header-title .logo-img {
  display: inline-block;
  height: 100%;
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
}
.header-title .text-img {
  display: inline-block;
  height: 80%;
  width: auto;
  vertical-align: middle;
}

@media (max-width: 999px) {
  
  .app {
    --horizontal-margin: 50px;
  }

  .header {
    --title-width: 80%;
  }

  .header-title {
    font-size: 14px;
  }

  .open-sections-button {
    position: absolute;
    right: var(--horizontal-margin);
    top: 50%;
    transform: translateY(-50%);
  }

  .close-sections-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .header-sections {
    position: absolute;
    top: 0;
    width: 200px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
    padding-top: 75px;
  }
  .header-sections.closed {
    right: -200px;
  }
  .header-sections.open {
    right: 0px;
  }

  .section {
    margin-bottom: 5px;
    color: white;
    background-color: rgba(var(--secondary-color), 0.7);
    padding: 10px;
    cursor: pointer;
  }
  .section:hover {
    background-color: rgba(var(--secondary-color), 1);
  }

}

@media (min-width: 1000px) {

  .header-title {
    font-size: 18px;
  }

  .open-sections-button {
    display: none;
  }

  .close-sections-button {
    display: none;
  }

  .header-sections {
    position: absolute;
    left: calc(var(--horizontal-margin) + var(--title-width));
    right: var(--horizontal-margin);
    top: 50%;
    transform: translateY(-50%);
  }
  .header-sections .section {
    float: right;
    padding: 6px 10px 5px 10px;
    border-radius: 5px;
    margin-left: 20px;
    transition: all 0.3s;
    cursor: pointer;
    color: black;
    border-bottom: 3px solid rgba(var(--secondary-color), 0);
  }
  .header-sections .section:hover {
    background-color: rgba(var(--secondary-color), 0.3);
  }
  .header-sections .section.selected {
    background-color: rgba(var(--secondary-color), 1);
    color: white;
  }

}

.view-container {
  position: relative;
  flex-grow: 1;
  padding-top: 75px;
  background-color: white;
}

.view-container .background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.08;
  background-image: url(./assets/bg.jpg);
  background-position-x: center;
}

.view {
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
  padding: 40px var(--horizontal-margin) 50px var(--horizontal-margin);
  z-index: 2;
}


.footer {
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  padding: 25px var(--horizontal-margin);
  color: rgb(80, 80, 80);
  background-color: rgb(245, 245, 245);
}