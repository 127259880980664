.portfolio {
    margin-bottom: 80px;
}

.portfolio .admin-bar {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
}

.portfolio .admin-bar .label {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
}

.portfolio .admin-bar .options {
    padding: 10px 20px;
}

.portfolio .portfolio-header {
    position: relative;
    margin-bottom: 40px;
}

.portfolio .portfolio-header .portfolio-logo {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid rgba(var(--secondary-color), 0.7);
    box-sizing: border-box;
    overflow: hidden;
    background-color: rgba(235, 235, 235, 0.5);
}
.portfolio .portfolio-header .portfolio-logo img {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    transform: translateY(-50%);
}

.portfolio .portfolio-header .portfolio-name {
    display: inline-block;
    width: calc(100% - 60px);
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    font-size: 34px;
    font-weight: 600;
    vertical-align: top;
    word-wrap: break-word;
}

@media screen and (max-width: 600px) {
    .portfolio .portfolio-header .portfolio-logo {
        width: 120px;
        height: 120px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .portfolio .portfolio-header .portfolio-name {
        width: 100%;
        padding: 0px;
        padding-top: 10px;
        text-align: center;
        font-size: 36px;
    }
}

.portfolio .icon-info {
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.portfolio .icon-info span {
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .portfolio .icon-info {
        font-size: 14px;
    }
}

.portfolio .primary-services {
    margin-top: 40px;
    margin-bottom: 10px;
}

.portfolio .primary-services label {
    font-weight: 600;
    font-size: 16px;
}

.portfolio .primary-services span {
    background-color: rgba(136, 201, 44, 0.228);
    padding: 2px 3px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
}

.portfolio .secondary-services label {
    font-weight: 600;
    font-size: 16px;
}

.portfolio .secondary-services span {
    background-color: rgba(44, 136, 201, 0.228);
    padding: 2px 3px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
}

.portfolio h2 {
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.8);
}

.portfolio .work-sample {
    display: block;
    position: relative;
    height: 40px;
    background-color: rgba(245, 245, 245);
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: all 0.2s;
}
.portfolio .work-sample:hover .work-sample-icon {
    background-color: rgba(220, 220, 220);
}

.portfolio .work-sample .work-sample-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(230, 230, 230);
    padding: 10px 20px;
}

.portfolio .work-sample .work-sample-name {
    position: absolute;
    left: 80px;
    top: 50%;
    font-weight: 600;
    transform: translateY(-50%);
}