.portfolio-preview {
    background-color: rgba(var(--primary-color), 0.2);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    transition: all 0.2s;
    cursor: pointer;
}

.portfolio-preview:hover {
    background-color: rgba(var(--primary-color), 0.4);
    transform: scale(1.006);
}

.portfolio-preview .logo {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    height: 50px;
    width: 50px;
    overflow: hidden;
    border: 2px solid rgba(var(--secondary-color), 0.7);
    box-sizing: border-box;
    border-radius: 50%;
}
.portfolio-preview .logo img{
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.portfolio-preview .name {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 50px);
    font-size: 26px;
    font-weight: 600;
    padding-left: 20px;
    box-sizing: border-box;
}

.portfolio-preview .name h1 {
    font-size: 26px;
    margin: 0px;
    font-weight: 600;
}
.portfolio-preview .name h2 {
    font-size: 14px;
    margin: 0px;
    margin-top: 5px;
    font-weight: 500;
}

@media (max-width: 500px) {
    .portfolio-preview .logo {
        height: 40px;
        width: 40px;
    }
    .portfolio-preview .name {
        width: calc(100% - 50px);
        font-size: 24px;
        padding-left: 10px;
    }
    .portfolio-preview .name h1 {
        font-size: 18px;
    }
    .portfolio-preview .name h2 {
        font-size: 12px;
        margin-top: 5px;
    }
}


.portfolio-preview .primary-services {
    margin-bottom: 10px;
}

.portfolio-preview .primary-services label {
    font-weight: 500;
    font-size: 16px;
}

.portfolio-preview .primary-services span {
    background-color: rgba(136, 201, 44, 0.228);
    padding: 2px 3px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
}

.portfolio-preview .secondary-services label {
    font-weight: 500;
    font-size: 16px;
}

.portfolio-preview .secondary-services span {
    background-color: rgba(44, 136, 201, 0.228);
    padding: 2px 3px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
}